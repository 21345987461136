import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import Button from "../UI/Button";
import styles from "./Header.module.css";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <header className={styles.header}>
      <Navbar>
        <Container>
          <Navbar id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Link to={"/"}>
                <Button className={`btn-primary ${styles.btn}`}>
                  الصفحة الرئيسية
                </Button>
              </Link>
            </Nav>
          </Navbar>
          <Navbar.Brand>
            <Link to={"/"}>
              <img
                className={styles.logo}
                src={"/images/logo-login.png"}
                alt={"logo"}
              />
            </Link>
          </Navbar.Brand>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
