import { Container, Row } from "react-bootstrap";
import { Route, Routes } from "react-router-dom";
import Header from "./components/Layout/Header";
import Footer from "./components/Layout/Footer";
import WeightMain from "./pages/weightGain/WeightMain";
import WeightSecondStep from "./pages/weightGain/WeightSecondStep";
import Results from "./pages/Results";

function App() {
  return (
    <>
      <Header />
      <main>
        <Container style={{ marginBottom: "2rem" }}>
          <Row
            className={"align-items-center ms-0 mt-5"}
            style={{ width: "100%" }}
          >
            <Routes>
              <Route path={"/"} element={<WeightMain />} />
              <Route path={"/step-2"} element={<WeightSecondStep />} />
              <Route path={"/result"} element={<Results />} />
            </Routes>
          </Row>
        </Container>
      </main>
      <Container>
        <Footer />
      </Container>
    </>
  );
}

export default App;
