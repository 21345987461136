import React, { useEffect, useRef, useState } from "react";
import Box from "../../components/UI/Box";
import styles from "./WeightSecondStep.module.css";
import { Row } from "react-bootstrap";
import Divider from "../../components/UI/Divider";
import { Button, Input } from "antd";
import Title from "../../components/UI/Title";
import { useLocation, useNavigate } from "react-router-dom";
import { parseArabic } from "../healper";

const WeightSecondStep = () => {
  const [firstError, setFirstError] = useState(false);
  const [secondError, setSecondError] = useState(false);
  let navigate = useNavigate();
  const location = useLocation();
  const request: any = location.state;
  const weight = useRef(null);
  const length = useRef(null);
  const [result, setResult] = useState(null);
  const displayResults = async () => {
    setFirstError(false);
    setSecondError(false);
    const data = {
      weight: parseArabic(weight.current.input.value),
      length: parseArabic(length.current.input.value),
      babyCount: request.with,
    };
    console.log("weight: " + data.weight);

    if (data.weight == null || data.weight == 0) {
      setFirstError(true);
      return;
    }
    if (data.length == null || data.length == 0) {
      setSecondError(true);
      return;
    }
    await getResults(data);
    console.log("result: " + result);
  };
  useEffect(() => {
    console.log(result);
    if (result != null) {
      let path = `/result`;
      navigate(path, {
        replace: false,
        state: {
          result: result,
          title: result,
          subTitle:
            "هذه النتيجة تعكس الحد الأدنى والحد الأعلى المسموح لزيادة الوزن خلال كامل فترة الحمل.",
        },
      });
    }
  }, [result]);

  const getResults = (data) => {
    const height = data.length / 100;
    const bmi = data.weight / (height * height);
    console.log(bmi);
    if (data.babyCount === 1) {
      if (bmi < 18.5) {
        setResult("12.7 kg - 18.2 kg");
      } else if (bmi >= 18.5 && bmi <= 24.9) {
        setResult("11.4 kg - 15.9 kg");
      } else if (bmi >= 25.0 && bmi <= 29.9) {
        setResult("6.8 kg - 11.4 kg");
      } else if (bmi >= 30) {
        setResult("5 kg - 9.1 kg");
      }
    } else if (data.babyCount === 2) {
      if (bmi < 18.5) {
        setResult("18.5 kg - 27 kg");
      } else if (bmi >= 18.5 && bmi <= 24.9) {
        setResult("17 kg - 15.925 kg");
      } else if (bmi >= 25.0 && bmi <= 29.9) {
        setResult("14 kg - 23 kg");
      } else if (bmi >= 30) {
        setResult("11 kg - 19 kg");
      }
    } else {
      let path = `/`;
      navigate(path, {
        replace: false,
      });
    }
  };
  return (
    <Box customClass={`form-box ${styles["form-box"]}`}>
      <Row className={`${styles["main-row"]}`}>
        <Title
          color={"var(--secondary)"}
          fontSize={"1.4rem"}
          className={`form-label ${styles["form-label"]}`}
        >
          الرجاء إدخال وزنك قبل الحمل
        </Title>
        <Input
          id={"weight"}
          size={"small"}
          placeholder={"60 كغ"}
          ref={weight}
          status={firstError ? "error" : ""}
          required
        />
      </Row>
      <Divider marginTop={"2rem"} marginBottom={"2rem"} />
      <Row className={`main-row  ${styles["main-row"]}`}>
        <Title
          color={"var(--secondary)"}
          fontSize={"1.4rem"}
          className={`form-label  ${styles["form-label"]}`}
        >
          الرجاء إدخال طولك
        </Title>
        <Input
          id={"weight"}
          size={"small"}
          placeholder={"160 سنتيمتر"}
          ref={length}
          status={secondError ? "error" : ""}
          required
        />
      </Row>
      <Button type="primary" onClick={displayResults}>
        الناتج
      </Button>
    </Box>
  );
};

export default WeightSecondStep;
